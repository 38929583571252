import { addLocaleData } from 'react-intl';
import Enlang from './entries/en-US';
import saLang from './entries/es-ES';

const AppLocale = {
  en: Enlang,
  es: saLang,
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.es.data);

export default AppLocale;
