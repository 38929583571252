import { language } from '../config';

const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: 'english',
      locale: 'en',
    },
    {
      languageId: 'spanish',
      locale: 'es',
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selectedLanguage = config.options[0];
  config.options.forEach((defaultLanguage) => {
    if (defaultLanguage.languageId === lang) {
      selectedLanguage = defaultLanguage;
    }
  });
  return selectedLanguage;
}

export default config;
