const theme = {};

// http://paletton.com/#uid=13w0u0kv8BOjjIXoPEdBxsnE8m9

theme.palette = {
  base: [
    '#ffffff', // 0: white
    '#000000', // 1: black
    'rgb(240,242,250)', // 2: light medium gray -- main background
    '#f5f5f5', // 3: medium gray (#f5f5f5)
    '#fafafa', // 4: light gray
    '#bebebe', // 5: dark gray
    '#8c8c8c', // 6: very dark gray
    'rgba(250,250,250,.5)', // 7: light gray / 50%
  ],
  border: [
    '#e8e8e8', // 0: light gray
    '#d8d8d8', // 1: dark gray 65% (#d9d9d9)
  ],
  error: [
    '#f5222d', // 0: red
    '#ff4d4f', // 1: red / hover
  ],
  primary: [
    '#1071b7', // 0: brand color / 100%
    'rgba(16,114,184,0.9)', // 1: brand color / 90%
    'rgba(16,114,184,0.8)', // 2: brand color / 80%
    'rgba(16,114,184,0.7)', // 3: brand color / 70%
    'rgba(16,114,184,0.6)', // 4: brand color / 60%
    'rgba(16,114,184,0.5)', // 5: brand color / 50%
    'rgba(16,114,184,0.4)', // 6: brand color / 40%
    'rgba(16,114,184,0.3)', // 7: brand color / 30%
    'rgba(16,114,184,0.2)', // 8: brand color / 20%
    'rgba(16,114,184,0.1)', // 9: brand color / 10%
    'rgba(16,114,184,0.05)', // 10: brand color / 5%
    'rgba(16,114,184,0.03)', // 11: brand color / 3%
  ],
  secondary: [
    '#064180', // 0: secondary-1
    '#09519c', // 1: secondary-2
  ],
  shadow: [
    'rgba(0,21,41,0.08) 0 1px 3px 0', // 0: bottom only
    'rgba(0,21,41,0.08) -1px 0 3px 0', // 1: right only
    'rgba(0,21,41,0.08) 0 -1px 3px 0', // 2: top only
    'rgba(0,21,41,0.22) 0 1px 4px 0', // 3: dashboard add widget list
    'rgba(16,114,184,0.2)', // 4: select dropdown box shadow
  ],
  success: [
    'rgba(82,196,26,1)', // 0: green
  ],
  text: [
    'rgba(0,0,0,0.65)', // 0: black 85%
    'rgba(0,0,0,0.65)', // 1: dark gray 65%
    'rgba(0,0,0,0.45)', // 2: medium gray 45%
    'rgba(0,0,0,0.25)', // 3: light gray 25%
    'rgba(255,255,255,1)', // 4: white
  ],
  warning: [
    'rgba(250,173,20,1)', // 0: yellow
  ],
};

theme.fonts = {
  // primary: '"Open Sans", sans-serif;',
  primary: '"Source Sans Pro", sans-serif;',
  // primary: '"Montserrat", sans-serif;',
};

export default theme;
