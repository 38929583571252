import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Assessment from "./containers/assessment";
import { rootUrl } from "./config";
import NotFound from "./containers/notfound";

const PublicRoutes = () => (
  <BrowserRouter>
    <Switch>
      <Route component={Assessment} exact path="/:assessmentToken" />
      <Route component={Assessment} exact path="/:companyName/:practiceIds/:userName/:clientName" />
      {/* no assessment token, send to rootUrl */}
      <Route path="/" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default PublicRoutes;
