import styled from 'styled-components';

const LayoutContentWrapper = styled.div`
  background: #ffffff;
  border: solid 1px #f5f5f5;
  border-radius: 4px;
  margin: 24px 24px 0;
  padding: 24px;
  &.templateWrapper{
    max-width:600px;
    width:100%;
    margin:24px auto;
  }
  @media only screen and (max-width: 767px) {
    margin: 24px 0 0;
  }
  .logo-wrapper {
    display: flex;
    justify-content: center;
    img {
      height: 46px;
    }
  }
`;

export default LayoutContentWrapper;
