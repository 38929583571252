import theme from './defaultTheme';

const globalstyle = String.raw`
.ant-layout-sider {
  background: ${theme.palette.secondary[0]};
  .ant-menu.ant-menu-dark {
    .ant-menu-inline {
      &.ant-menu-sub {
        background: #043567;
        box-shadow: none;
      }
      .ant-menu-item {
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }
    .ant-menu-item-selected {
      background: ${theme.palette.primary[0]};
    }
  }
}

.user-notification-menu {
  .ant-dropdown-menu-item {
    padding: 16px;
    &:not(:last-child){
      border-bottom: 1px solid rgb(232,232,232);
    }
    &:last-child {
      font-size: 12px;
      padding: 8px 0;
      text-align: center;
    }
    &:hover {
      background: none;
    }
    .menu-item-wrapper {
      display: flex;
      justify-content: space-between;
      width: 200px;
      a {
        color: ${theme.palette.text[1]};
        &:focus {
          text-decoration: none;
        }
      }
      .note {
        font-size: 12px;
      }
    }
  }
}

.ant-popover {
  width: 250px;
}

.ant-back-top {
  right: 24px;
}

.menu-drawer {
  .ant-drawer-header {
    align-items: center;
    border-bottom: none;
    display: flex;
    height: 116px;
    padding: 12px 12px 12px 24px;
    .ant-drawer-title {
      color: ${theme.palette.text[1]};
      font-size: 32px;
      font-weight: 700;
      line-height: 28px;
    }
  }
  .ant-drawer-close-x {
    color: ${theme.palette.text[1]};
    font-size: 24px;
    font-weight: 700;
    height: 116px;
    line-height: 52px;
    width: 64px;
  }
}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  ol,
  ul,
  dl,
  li,
  input,
  input[type="number"],
  input[type="password"],
  input[type="text"],
  textarea,
  span,
  div,
  html,
  body,
  html a {
    font-family: ${theme.fonts.primary};
    margin-bottom: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: ${theme.palette.primary[0]};

    &:hover,
    &:focus {
      color: ${theme.palette.primary[2]};
    }
  }

  ::selection {
    background: rgba(81,140,254,1);
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .caption,
  .display-1,
  .display-2,
  .display-3,
  .headline,
  .title {
    color: ${theme.palette.text[0]};
  }

  .caption {
    font-size: 12px;
    line-height: 20px;
  }

  .caption-error {
    display: block;
    font-size: 12px;
    line-height: 20px;
    color: ${theme.palette.error[0]};
  }

  .display-1 {
    font-size: 24px;
    line-height: 32px;
  }

  .display-2 {
    font-size: 34px;
    line-height: 40px;
  }

  .display-3 {
    font-size: 45px;
    line-height: 48px;
  }

  .headline {
    font-size: 20px;
    line-height: 30px;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
  }

  .ant-badge-status-default {
    background: ${theme.palette.base[5]};
  }

  .ant-btn:hover, .ant-btn:focus {
    border-color: ${theme.palette.primary[0]};
    color: ${theme.palette.primary[0]};
  }

  &.ant-btn-danger {
    &:hover {
      background-color: ${theme.palette.error[1]};
      border-color: ${theme.palette.error[1]};
      color: ${theme.palette.base[0]};
    }

    &:focus {
      background-color: ${theme.palette.base[3]};
      border-color: ${theme.palette.border[1]};
      color: ${theme.palette.error[0]};

      &:hover {
        background-color: ${theme.palette.error[1]};
        border-color: ${theme.palette.error[1]};
        color: ${theme.palette.base[0]};
      }
    }
  }

  .ant-confirm-body-wrapper {
    .ant-btn-primary {
      background: ${theme.palette.primary[0]};
      border-color: ${theme.palette.primary[0]};
      color: ${theme.palette.base[0]};

      &:hover {
        background-color: ${theme.palette.primary[2]};
        border-color: transparent;
        color: ${theme.palette.base[0]};
      }

      &:focus {
        color: ${theme.palette.base[0]};
        text-decoration: none;
      }
    }
  }

  .ant-confirm-body {
    i.anticon.anticon-close-circle {
      color: ${theme.palette.error[1]};
    }
  }

  .ant-select-auto-complete {
    &.ant-select {
      .ant-input {
        &:focus,
        &:hover {
          border-color: ${theme.palette.primary[0]};
        }
      }

      .ant-input-affix-wrapper {
        .anticon-search {
          color: ${theme.palette.text[3]};
        }
      }
    }
  }

  .ant-pagination-item-active {
    border-color: ${theme.palette.primary[0]};

    a {
      color: ${theme.palette.primary[0]};
    }

    &:hover,
    &:focus {
      border-color: ${theme.palette.primary[1]};

      a {
        &:hover,
        &:focus {
          color: ${theme.palette.primary[1]};
        }
      }
    }
  }

  .ant-radio-button-wrapper-checked {
    background: transparent;
    border-color: ${theme.palette.primary[0]};
    color: ${theme.palette.primary[0]};

    &:first-child {
      border-color: ${theme.palette.primary[0]};
    }
  }

  .ant-radio-button-wrapper {
    &:hover,
    &:focus {
      color: ${theme.palette.primary[0]};
    }
  }

  .ant-form-explain {
    color: ${theme.palette.error[0]};
  }

  &.uni-modal {
    .ant-modal-close-x {
      font-size: 14px;
      font-weight: 400;
    }

    &.uni-widget-modal {
      .ant-modal-body {
        background: ${theme.palette.base[4]};

        .widget-list-card {
          background: ${theme.palette.base[0]};
          border-radius: 2px;
          box-shadow: ${theme.palette.shadow[3]};
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: 16px;
          padding: 16px 24px;

          &:last-of-type {
            margin-bottom: 0;
          }

          .widget-list-name {
            display: flex;
            flex-basis: 100%;
            flex-direction: column;

            > div {
              align-items: flex-end;
              color: ${theme.palette.text[0]};
              display: flex;
              flex-direction: row;
              font-size: 14px;
              line-height: 22px;

              span {
                color: ${theme.palette.text[2]};
                font-size: 12px;
                line-height: 20px;
                margin-left: 8px;
              }
            }
          }

          .widget-list-details {
            display: flex;
            flex-basis: 100%;
            flex-direction: column;

            > div {
              align-items: flex-end;
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .list-text {
                color: ${theme.palette.text[1]};
                font-size: 12px;
                line-height: 20px;
                margin-right: 16px;
                max-width: 75%;
              }
            }
          }

          &.disabled {
            background: ${theme.palette.base[4]};

            .widget-list-name {
              > div {
                color: ${theme.palette.text[2]};
              }
            }

            .widget-list-details {
              > div {
                .list-text {
                  color: ${theme.palette.text[2]};
                }
              }
            }

          }

        }
      }
    }
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      background-color: ${theme.palette.base[3]} !important;
    }
  }

  .ant-checkbox-input {
    &:focus {
      + {
        .ant-checkbox-inner {
          border-color: ${theme.palette.primary[0]};
        }
      }
    }
  }

  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${theme.palette.primary[0]};
      }
    }
  }

  .ant-checkbox-inner {
    &:after {
      border: 1px solid ${theme.palette.base[0]};
      border-left: 0;
      border-top: 0;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${theme.palette.primary[0]};
      border-color: ${theme.palette.primary[0]};

      &:after {
        border: 1px solid ${theme.palette.base[0]};
        border-top: 0;
        border-left: 0;
      }
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &:after {
        background-color: ${theme.palette.primary[0]};
      }
    }
  }

  .ant-input {
    font-family: ${theme.fonts.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ant-input-affix-wrapper {
    &:hover {
      .ant-input {
        &:not(.ant-input-disabled) {
          border-color: ${theme.palette.primary[0]};
        }
      }
    }
  }

  &.ant-select {
    .ant-select-selection {
      &:hover {
        border-color: ${theme.palette.primary[0]};
      }
    }

    &.ant-select-focused {
      .ant-select-selection {
        &:focus,
        &:active {
          border-color: ${theme.palette.primary[0]};
        }
      }
    }

    &.ant-select-open {
      .ant-select-selection {
        border-color: ${theme.palette.primary[0]};
        box-shadow: 0 0 0 2px ${theme.palette.shadow[4]};
      }
    }

    &.ant-select-disabled {
      .ant-select-selection {
        &:hover,
        &:focus,
        &:active {
          border-color: ${theme.palette.primary[0]};
        }
      }

      span.ant-badge {
        opacity: .4;
      }
    }
  }

  .ant-select-focused {
    .ant-select-selection {
      border-color: ${theme.palette.primary[0]};
      box-shadow: 0 0 0 2px ${theme.palette.shadow[4]};
    }
  }
  .ant-select-selection {
    &:focus {
      border-color: ${theme.palette.primary[0]};
      box-shadow: 0 0 0 2px ${theme.palette.shadow[4]};
    }
    &:active {
      border-color: ${theme.palette.primary[0]};
      box-shadow: 0 0 0 2px ${theme.palette.shadow[4]};
    }
  }

  .ant-select-dropdown-menu-item {
    &:hover {
      background-color: ${theme.palette.primary[10]};
    }
  }

  .ant-select-dropdown-menu-item-active {
    background-color: ${theme.palette.primary[10]};
  }

  .ant-dropdown-menu-item {
    &:hover {
      background-color: ${theme.palette.primary[10]};
    }
    a {
      &:hover {
        color: ${theme.palette.text[1]};
      }
      &:focus {
        color: ${theme.palette.text[1]};
      }
    }

  }
  .ant-dropdown-menu-submenu-title {
    &:hover {
      background-color: ${theme.palette.primary[10]};
    }
    a {
      &:hover {
        color: ${theme.palette.text[1]};
      }
      &:focus {
        color: ${theme.palette.text[1]};
      }
    }

  }



  .ant-tag {
    font-family: ${theme.fonts.primary};
  }

  .has-error {
    .ant-input {
      border-color: ${theme.palette.error[0]};

      &:hover {
        border-color: ${theme.palette.error[0]};
      }
    }

    .ant-form-explain {
      border-color: ${theme.palette.error[0]};
    }

    .ant-form-split {
      border-color: ${theme.palette.error[0]};
    }
  }

  .uni-confirm-delete {
    i.anticon.anticon-close-circle {
      color: ${theme.palette.error[0]};
    }
  }

  .uni-confirm-modal {
    .ant-confirm-content {
      margin-left: 38px;
      margin-top: 12px;

      .uni-yes-confirm {
        margin: 24px 0 8px 0;
      }

      .ant-form-item {
        margin: 0;
      }
    }

    .ant-modal-footer {
      border-top: none;
      padding: 12px 24px 24px;
    }
  }

  .ant-menu-dark {
    background: ${theme.palette.secondary[0]};
  }

  @media only screen and (max-width: 767px) {
    .uni-body {
      height: 100%;
      overflow: hidden;
      position: fixed;
      width: 100%;
    }
  }
`;

export default globalstyle;
