import React from "react";
import ContentWrapper from "../assessment/index.style";
import { Layout } from "antd";

const NotFound = () => {
  return (
    <ContentWrapper>
      <Layout className="layout">
        <div className="loading-wrapper">
          <p>
            Your Link is Invalid. Please check your <b>Assessment Link</b>
          </p>
        </div>
      </Layout>
    </ContentWrapper>
  );
};

export default NotFound;
