import styled from 'styled-components';

const ContentWrapper = styled.div`
  min-height: 100vh;
  .steps-content {
    .message-content {
      p {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .steps-action{
    p{
      margin:12px 0;
    }
  }
  .layout {
    background: rgba(249,250,252,1);
    border: 1px solid rgba(238,242,244,1);
    transition: all .2s;
    .ant-layout-content {
      .content-wrapper {
        min-height: calc(100vh - 95px);
        label {
          color: rgba(0,0,0,.65);
          padding-right: 24px;
          &.ant-form-item-required {
            &:before {
              display: none;
            }
          }
        }
        .ant-form-item {
          .ant-form-item-label {
            white-space: pre-wrap;
            &:after {
              color: #f5222d;
              content: "*";
              display: inline-block;
              font-family: SimSun;
              font-size: 12px;
              line-height: 1;
              margin-left: -20px;
              top: 2px;
              vertical-align: super;
            }
          }
        }
        .ant-slider-track {
          background: none;
        }
      }
    }
    .ant-layout-footer {
      background: rgba(249,250,252,1);
      padding: 24px;
      text-align: center;
    }
    .loading-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 100vh;
    }
    .slider-wrapper {
      display: flex;
      flex-direction: column;
      transition: all .2s;
      .slider-wrapper-inner {
        width: 500px;
      }
      .label-wrapper {
        display: flex;
        justify-content: space-between;
        /* width: 500px; */
        padding-left: 6px;
        width: 494px;
        &> div:nth-child(2) {
          margin-left: -16px;
        }
      }
      @media only screen and (max-width: 550px) {
        width: 100%;
        .slider-wrapper-inner {
          width: 100%;
        }
        .label-wrapper {
          /* width: 100%; */
          width: calc(100% - 6px);
        }
      }
    }
  }
`;

export default ContentWrapper;
