const ENV = process.env.REACT_APP_ENV;
let merged = {};

const defaultConfig = {
  apiUrl: "http://localhost:3002",
  rootUrl: "http://localhost:3001",
  language: "english",
  maxSize: 524288000, // 500MB,
  practiceConfig: {
    footerText: "Powered by Unitifi",
    practiceName: "Unitifi",
    rowsPerPage: 20,
  },
  themeConfig: {
    layout: "themedefault",
    sidebar: "themedefault",
    theme: "themedefault",
    topbar: "themedefault",
  },
  validDocTypes: ["doc", "dot", "docx", "docm", "docb", "csv", "xlsx", "xlsm", "xltx", "xltm", "ppt", "pot", "pps", "pptx", "potm", "ppam", "ppsx", "ppsm", "sldx", "sldm", "pages", "pdf", "key", "numbers", "txt", "heic", "jpg", "jpeg", "png", "gif"],
  validMediaTypes: ["heic", "jpg", "jpeg", "png", "gif", "mov", "mp4", "mkv", "webm", "m4v"],
};

if (ENV === "staging") {
  merged = Object.assign({}, defaultConfig, {
    apiUrl: process.env.REACT_APP_API_URL,
    rootUrl: process.env.REACT_APP_ROOT_URL,
    // apiUrl: "https://api-staging.unitifi.com",
    // rootUrl: "http://app-staging.unitifi.com",
  });
} else if (ENV === "production") {
  merged = Object.assign({}, defaultConfig, {
    apiUrl: process.env.REACT_APP_API_URL,
    rootUrl: process.env.REACT_APP_ROOT_URL,
    // apiUrl: "https://gql.unitifi.com",
    // rootUrl: "https://unitifi.com",
  });
} else {
  merged = defaultConfig;
}

export const { apiUrl, rootUrl, language, maxSize, practiceConfig, themeConfig, validDocTypes, validMediaTypes } = merged;
