import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Button, Form, Icon, Input, Layout, notification, Progress, Radio, Select, Slider, Spin } from "antd";
import ContentWrapper from "./index.style";
import LayoutContent from "../../layout/layoutContent";
import LayoutContentWrapper from "../../layout/layoutWrapper";
import { decrypt } from "../../utils/crypt";

const { Content, Footer } = Layout;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const { Option } = Select;

const GET_CLIENT_ASSESSMENT = gql`
  query($assessmentToken: String!) {
    getClientAssessment(assessmentToken: $assessmentToken) {
      advisor {
        firstName
        lastName
      }
      advisorId
      assessment {
        name
        questions
      }
      client {
        email
        firstName
        lastName
        submittedByClient
      }
      clientId
      practiceId
      practice {
        name
        siteLogo
        userInviteDomain
      }
    }
  }
`;

const SUBMIT_CLIENT_ASSESSMENT = gql`
  mutation submitClientAssessment($advisorId: Int!, $clientId: Int!, $practiceId: Int!, $assessmentToken: String, $responses: JSON!) {
    submitClientAssessment(clientId: $clientId, advisorId: $advisorId, practiceId: $practiceId, assessmentToken: $assessmentToken, responses: $responses) {
      practice
      ok
      errors {
        message
        path
      }
    }
  }
`;

function GetInitialValue(data, value) {
  if (value === 2) {
    return data.firstName;
  }

  if (value === 3) {
    return data.lastName;
  }

  if (value === 4) {
    return data.email;
  }

  return null;
}

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

const Assessment = (props) => {
  const { form } = props;
  const { getFieldProps, getFieldDecorator, getFieldValue } = form;
  const [answers, setAnswers] = useState([]);
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [assessmentToken, setAssessmentToken] = useState(null);
  const [showAdditionalDecisionMaker, setShowAdditionalDecisionMaker] = useState(false);
  const [loadClientAssessment, { loading: dataLoading, error, data }] = useLazyQuery(GET_CLIENT_ASSESSMENT);
  const [submitAssessment] = useMutation(SUBMIT_CLIENT_ASSESSMENT);

  useEffect(() => {
    let params = props.match.params;
    if (params.userName) {
      let assessToken = params.userName;
      assessToken = decrypt(assessToken).split("_");
      if (assessToken.length > 0) {
        assessToken = assessToken[assessToken.length - 1];
        setAssessmentToken(assessToken);
        loadClientAssessment({ variables: { assessmentToken: assessToken } });
      }
    }
  }, [loadClientAssessment, props.match.params]);

  // useEffect(() => {
  //   loadClientAssessment({ variables: { assessmentToken: props.match.params.assessmentToken } });
  // }, [loadClientAssessment, props.match.params.assessmentToken]);

  const handleSubmit = (event) => {
    event.preventDefault();

    props.form.validateFields((err, values) => {
      if (!err) {
        setLoading(true);

        const { advisorId, clientId, practiceId } = data.getClientAssessment;
        const responses = Object.values(
          [
            ...answers,
            ...Object.entries(values).map(([key, value]) => ({
              id: Number(key),
              answer: value.toString(),
            })),
          ].reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
        );

        const variables = {
          assessmentToken: assessmentToken,
          advisorId,
          clientId,
          practiceId,
          responses,
        };
        submitAssessment({ variables })
          .then(({ data }) => {
            if (data.submitClientAssessment.ok && !data.submitClientAssessment.errors) {
              setCurrent(current + 1);
              setLoading(false);

              setTimeout(() => {
                window.location = data.submitClientAssessment.practice !== null ? data.submitClientAssessment.practice : "https://unitifi.com";
              }, 5000);

              return;
            }

            handleError(data.submitClientAssessment.errors);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      }
    });
  };

  const handleError = (errors) => {
    setLoading(false);

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  };

  const next = (event) => {
    event.preventDefault();

    props.form.validateFieldsAndScroll({ scroll: { offsetTop: 70 } }, (err, values) => {
      if (!err) {
        window.scrollTo(0, 0);

        setAnswers((prevAnswers) =>
          Object.values(
            [
              ...prevAnswers,
              ...Object.entries(values).map(([key, value]) => ({
                id: Number(key),
                answer: value.toString(),
              })),
            ].reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
          )
        );
        setCurrent((prevCurrent) => prevCurrent + 1);
      }
    });
  };

  const prev = () => {
    window.scrollTo(0, 0);

    setAnswers((prevAnswers) =>
      Object.values(
        [
          ...prevAnswers,
          ...Object.entries(props.form.getFieldsValue())
            .filter(([key, value]) => key !== null && value !== undefined && value !== null)
            .map(([key, value]) => ({
              id: Number(key),
              answer: value.toString(),
            })),
        ].reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
      )
    );
    setCurrent((prevCurrent) => prevCurrent - 1);
  };

  const onRadioChange = (e) => {
    setShowAdditionalDecisionMaker(e.target.value === "Yes");
  };

  const sliderTouched = (rule, value, callback, startValue, formFieldId) => {
    if (value && value.toString().endsWith(".000001")) {
      const error = formFieldId === 9 ? "Are you sure you are Moderate?" : "Are you sure you are Neutral?";

      props.form.setFields({
        [formFieldId]: {
          value: value - 0.000001,
          errors: [new Error(error)],
        },
      });
    }

    callback();
  };

  if (dataLoading) {
    return (
      <ContentWrapper>
        <Layout className="layout">
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </Layout>
      </ContentWrapper>
    );
  }

  if (error) {
    return (
      <ContentWrapper>
        <Layout className="layout">
          <div className="loading-wrapper">
            <p>Whoopsie - clients didn&apos;t load.</p>
          </div>
        </Layout>
      </ContentWrapper>
    );
  }

  if (!data) {
    return null;
  }

  const { advisor, assessment, client, practice } = data.getClientAssessment;

  const steps = [...new Set(data.getClientAssessment.assessment.questions.map((q) => q.page)), 7, 8];

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  if (client.submittedByClient) {
    return (
      <ContentWrapper>
        <Layout className="layout">
          <Content>
            <div className="content-wrapper">
              <LayoutContentWrapper>
                <LayoutContent>
                  <div className="logo-wrapper">
                    <img alt={practice.name} src={practice.siteLogo !== null ? practice.siteLogo : "https://s3-us-west-2.amazonaws.com/unitifi-prod-media/site-logos/unitifi.svg"} />
                  </div>

                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      margin: "24px 0 0 ",
                    }}>
                    <div style={{ marginBottom: "16px" }}>
                      <Progress type="circle" percent={100} />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}>
                      <p style={{ fontSize: "18px", marginBottom: "16px" }}>This assessment has been submitted.</p>
                      <p>Please contact your financial professional to review your personalized results or to request a new invitation.</p>
                    </div>
                  </div>
                </LayoutContent>
              </LayoutContentWrapper>
            </div>
          </Content>
          <Footer>Powered by Unitifi</Footer>
        </Layout>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <Layout className="layout">
        <Content>
          <div className="content-wrapper">
            <LayoutContentWrapper className="templateWrapper">
              <LayoutContent>
                <div className="logo-wrapper">
                  <img alt={practice.name} src={practice.siteLogo !== null ? practice.siteLogo : "https://s3-us-west-2.amazonaws.com/unitifi-prod-media/site-logos/unitifi.svg"} />
                </div>

                <div style={{ margin: "24px 0 0" }}>
                  {current > 0 ? <Progress percent={current !== 0 ? Math.round(((current - 1) / 7) * 100) : 0} /> : null}

                  <div className="steps-content" style={{ margin: "24px 0" }}>
                    {current === 0 ? (
                      <div className="message-content">
                        {practice.name === "Bernstein Financial Advisory" ? (
                          <>
                            <p>We each have unique values, goals and perspectives. You are more than just a set of numbers based on your age, appetite for risk and economic status.</p>
                            <p>
                              The assessment which you are about to take will not only help me understand your unique tolerance for risk, but it will also help me see how you go about making financial decisions. This will aid me in understanding the
                              most effective way to communicate with you, as well as guiding us toward investment decisions that will best fit your goals and comfort level.
                            </p>
                            <p>Thank you in advance for taking the time to help me understand how you think as an individual, and who you are as an investor. I hope you find the assessment beneficial!</p>
                            <p>Best regards,</p>
                            <p>
                              {advisor.firstName} {advisor.lastName}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>Greetings {client.firstName},</p>
                            <p>The assessment you have been invited to complete is powered by Unitifi - a subject matter expert in Behavioral Finance.</p>
                            <p>Typically, this assessment takes less than 10 minutes to complete. Your responses are confidential, and results are available immediately for your financial professional to review/share with you.</p>
                            <p>Insights from your unique report help provide a clear understanding of your financial behavior as it relates to your finances, communication preferences, and decision-making style.</p>
                            <p>Peace!</p>
                            {/*<p>
                              {advisor.firstName} {advisor.lastName}
                        </p>*/}
                            <p>
                              Jerry Szatko
                              <br />
                              CEO Unitifi
                            </p>
                          </>
                        )}
                      </div>
                    ) : null}

                    {current === 8 ? (
                      <div className="message-content">
                        {practice.name === "Bernstein Financial Advisory" ? (
                          <>
                            <p>Thank you for taking the time to complete this assessment.</p>
                            <p>I look forward to helping you set up a plan to achieve your financial goals.</p>
                          </>
                        ) : (
                          <>
                            <p>Thank you for taking the time to invest in your future by performing this important task.</p>
                            <p>We look forward to helping you achieve all that is important to you.</p>
                            <p>Sincerely,</p>
                            <p>
                              {advisor.firstName} {advisor.lastName}
                            </p>
                          </>
                        )}
                      </div>
                    ) : null}

                    <Form layout="vertical" onSubmit={handleSubmit}>
                      {assessment.questions
                        .filter((q) => q.page + 1 === current)
                        .map((i) => {
                          if (i.type === "Text" && i.id !== 2 && i.id !== 3 && i.id !== 4) {
                            return (
                              // if the text question is 7 or 8, hide unless 'Yes' is selected
                              // from additional decision maker radio question
                              ![7, 8].includes(i.id) || (showAdditionalDecisionMaker && [7, 8].includes(i.id)) ? (
                                <FormItem key={i.id} label={i.name}>
                                  {getFieldDecorator(`${i.id}`, {
                                    initialValue: answers.length > 0 && answers.filter((a) => a.id === i.id).length > 0 ? answers.filter((a) => a.id === i.id)[0].answer : GetInitialValue(client, i.id),
                                    rules: [
                                      {
                                        message: "This question is required.",
                                        required: true,
                                      },
                                    ],
                                  })(<Input placeholder={i.id === 44 ? "Enter Postal Code/ZIP Code" : null} style={{ maxWidth: "400px" }} />)}
                                </FormItem>
                              ) : null
                            );
                          }

                          if (i.type === "Radio") {
                            return (
                              <FormItem key={i.id} label={i.name}>
                                {getFieldDecorator(`${i.id}`, {
                                  initialValue: answers.length > 0 && answers.filter((a) => a.id === i.id).length > 0 ? answers.filter((a) => a.id === i.id)[0].answer : GetInitialValue(client, i.id),
                                  rules: [
                                    {
                                      message: "This question is required.",
                                      required: true,
                                    },
                                  ],
                                })(
                                  <RadioGroup onChange={i.id === 6 ? onRadioChange : null}>
                                    {i.options.map(({ text, value }) => (
                                      <Radio key={value} style={radioStyle} value={value}>
                                        {text}
                                      </Radio>
                                    ))}
                                  </RadioGroup>
                                )}
                              </FormItem>
                            );
                          }

                          if (i.type === "Slider") {
                            return (
                              <FormItem key={i.id} label={i.name}>
                                <div className="slider-wrapper">
                                  <div className="slider-wrapper-inner">
                                    {getFieldDecorator(`${i.id}`, {
                                      initialValue: answers.length > 0 && answers.filter((a) => a.id === i.id).length > 0 ? Number(answers.filter((a) => a.id === i.id)[0].answer) : i.config[3].start + 0.000001,
                                      rules: [
                                        {
                                          message: "This question is required.",
                                          required: true,
                                        },
                                        {
                                          validator: (rule, value, callback) => sliderTouched(rule, value, callback, i.config[3].start, i.id),
                                        },
                                      ],
                                    })(<Slider max={i.config[1].max} min={i.config[2].min} tipFormatter={null} />)}
                                  </div>
                                  <div className="label-wrapper">
                                    <div>{i.config[0].labels[0].left}</div>
                                    <div style={{}}>{i.config[0].labels[1].center}</div>
                                    <div>{i.config[0].labels[2].right}</div>
                                  </div>
                                </div>
                              </FormItem>
                            );
                          }

                          if (i.type === "Select") {
                            return (
                              <FormItem key={i.id} label={i.name}>
                                {getFieldDecorator(`${i.id}`, {
                                  initialValue: answers.length > 0 && answers.filter((a) => a.id === i.id).length > 0 ? answers.filter((a) => a.id === i.id)[0].answer : undefined,
                                  rules: [
                                    {
                                      message: "This question is required.",
                                      required: true,
                                    },
                                  ],
                                })(
                                  <Select placeholder={i.id === 41 ? "Select A Year" : "Select An Age"} style={{ maxWidth: "400px" }}>
                                    {range(i.config[1].min, i.config[0].max).map((n) => (
                                      <Option key={n} value={n.toString()}>
                                        {n.toString()}
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              </FormItem>
                            );
                          }

                          return null;
                        })}
                    </Form>
                  </div>
                  <div className="steps-action">
                    {current > 0 && ![8].includes(current) && (
                      <Button onClick={() => prev()} style={{ marginRight: 8 }}>
                        Previous
                      </Button>
                    )}
                    {current < steps.length - 1 && current !== 7 && (
                      <Button onClick={next} type="primary">
                        {current === 0 ? "Begin" : "Next"}
                      </Button>
                    )}
                    {current == 0 ? <p>By clicking on Begin, you are agreeing to share the results with your financial professional.</p> : ""}
                    {current === 7 && (
                      <Button loading={loading} onClick={handleSubmit} type="primary">
                        Done
                      </Button>
                    )}
                  </div>
                </div>
              </LayoutContent>
            </LayoutContentWrapper>
          </div>
        </Content>
        <Footer style={{ color: "rgba(0,0,0,0.45)" }}>Powered by Unitifi</Footer>
      </Layout>
    </ContentWrapper>
  );
};

export default Form.create()(Assessment);
