import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { IntlProvider } from 'react-intl';
import { LocaleProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import AppLocale from './language';
import PublicRoutes from './appRouter';
import client from './config/apollo';
import config, { getCurrentLanguage } from './language/config';
import theme from './theme';
import { themeConfig } from './config';

const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

const App = () => (
  <LocaleProvider locale={currentAppLocale.antd}>
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ThemeProvider theme={theme[themeConfig.theme]}>
        <ApolloProvider client={client}>
          <PublicRoutes />
        </ApolloProvider>
      </ThemeProvider>
    </IntlProvider>
  </LocaleProvider>
);

export default App;
